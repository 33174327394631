import React from "react";
import { graphql } from "gatsby";
import timeboxing from "../../images/timeboxing.jpg";
import Page from "../../components/Page";
import { GatsbyImage } from "gatsby-plugin-image"

export default function Timeboxing({ data }) {
  const image = data.image.childImageSharp.gatsbyImageData;
  const title = "Vad är Timeboxing?"

  return (
    <>
      <Page title={title} description="Timeboxing en tidsplaneringsmetod som kan hjälpa dig att öka din produktivitet där du allokerar ett fast tidsspann av tid för en aktivitet i förväg, du genomför sedan aktiviteten inom det tidsspannet. Du slutar alltså arbeta med aktiviteten när tiden är slut, och därefter sedan du om du har nått dina planerade mål.">
        <div className="container px-5 py-6 mx-auto">
          <article className="blogPost">
            {image && <GatsbyImage image={image} className="mb-5 rounded-xl" alt="spargris" />}
            <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-8">{title}</h1>
            <p>
              Kort sammanfattat så är timeboxing en <b>tidsplaneringsmetod</b> som kan hjälpa dig att öka din produktivitet där du allokerar ett fast tidspann av tid för varje aktivitet i förväg. Du genomför sedan aktiviteten inom det tidsspannet. Du slutar alltså arbeta med uppgiften när tiden är slut, och utvärderar därefter om du lyckades nå dina planerade mål eller inte.
            </p>
            <p>En av de mest produktiva entreprenörerna, Elon Musk, är en av dem som använder sig av en metod som liknar timeboxing. Han driver flera stora företag vilket gör det extra viktigt att han allokerar sin tid effektivt. Elon Musk brukar dela in sin tid i 5 minuters intervaller och allokerar sedan sin tid åt olika aktiviteter.</p>
            <h2>Hur använder man Timeboxing?</h2>
            <p>
              När du använder dig av timeboxing så fördelar du upp din tid i olika tidsperioder, vilket ska fungera som en strikt gräns för de aktiviteter som du fruktar annars skulle ta för lång tid. På så sätt bestämmer du själv i förväg hur mycket tid du kommer spendera på varje uppgift.
            </p>
            <p>
              Dessa tidsperioder kallar också ibland för timeboxes, och kan variera mycket i längd. Vissa perioder kan vara så lite som 15 minuter för korta aktiviter och upp till flera månader om det är ett mycket stort projekt. Anledning till varför vi använder oss av dessa tidsperioder eller timeboxes är för att det skall ge oss en specifik deadline med mål relaterade till den, men beroende på vad du gör kan den även vara relaterad till leveranser, en budget och milstolpar.
            </p>
            <p>
              En viktig del när du använder dig av timeboxing är dessutom att du utvärderar ditt resultat efter varje tidsperiod. Vid slutet av varje timebox skall du därför avgöra om du har utfört uppgiften som du skulle göra, och du bedömer även om du lyckades nå ditt mål, helt eller delvis.
            </p>
            <p>Om du exempelvis vill städa upp ditt arbetsyta och inte vill spendera en hel dag på detta kan du allokera en timebox på 30 minuter, för att därefter sluta när tiden har passerat oavsett om du lyckades städa allt eller inte.</p>
            <p>
              Målet med att allokera tidsperioder eller timeboxes är dessutom att du skall bli bättre på att avgöra hur lång tid olika uppgifter kommer att ta. I takt med att du använder tekniken kommer detta dessutom vara något som du kommer bli bättre på.
            </p>
            <h4>Timeboxing innefattar följande steg:</h4>
            <ol>
              <li><b>Hitta lämpliga uppgifter</b> - Vad behöver du göra just nu? Vad är ditt fokus?</li>
              <li><b>Definiera dina mål</b> - Vad vill du uppnå med uppgiften? Inom vilken tid ska du ha utfört arbetet?</li>
              <li><b>Sätt tiden</b> - Hur lång tid tror du att uppgiften kommer att ta? Vad är den maximala tiden som du kan lägga ner på uppgiften?</li>
              <li><b>Arbeta och utvärdera ditt resultat</b> - Lyckades du uppnå ditt uppsatta mål inom det givna tidspannet? Om inte, hur kan du öka sannolikheten att lyckas nästa gång</li>
            </ol>
            <img
              src={timeboxing}
              className="imageFullWidth"
              loading="lazy"
              alt="timeboxing"
            />
            <h2>Vad är fördelarna med Timeboxing?</h2>
            <p>
              Att fördela upp dina uppgifter i timeboxes kan ha flera fördelar,
              några av dem är:
            </p>
            <ul>
              <li>
                 Du kommer även göra de uppgifterna som är 'för korta' och som
                du normalt inte skulle gjort genom att planera inom dem.
              </li>
              <li>
                 Du kommer sluta att vara en perfektionist eftersom du tvingas
                avsluta efter en tidsperiod.
              </li>
              <li>
                 Det kan öka din motivation och du kommer hålla ett bättre
                fokus.
              </li>
              <li>
                 Det kan skapa en bättre balans eftersom du själv bestämer helt
                hur mycket tid du skall lägga på varje uppgift.
              </li>
            </ul>
            <h2>Några andra tips för timeboxing:</h2>
            <p>Nedan går vi igenom några andra tips som kan vara till använding.</p>
            <h4>1. Använd dig av en app</h4>
            <p>
              För att planera min tid så gillar jag att använda mig av Google
              Calender, men andra alternativ finns också såsom Trello och
              Clockify. Där Trello är ett verktyg för projekthantering och
              Clockify är en tidsmätningsapp.
            </p>
            <h4>2. Planera in pauser</h4>
            <p>
              För att man skall kunna prestera på topp är det även viktigt att
              låta hjärnan och kroppen vila i mellanåt, så kom ihåg att planera
              i pauser för att kunna vara så effektiv som möjligt. Exempelvis om
              du planerar in 50 minuter av produktivt arbete kan det vara en bra
              idé att ta en 10-minuters paus efter det.
            </p>
            <h4>3. Gör tiden visuell</h4>
            <p>
              Tid kan ofta kännas abstrakt, men genom att ha en timer som du kan
              hålla ett öga på då och då påminner det dig om att du måste
              begränsa tiden för ditt arbete till en fördefinierad timebox.
            </p>
            <br />
          </article>
        </div>
      </Page>
    </>
  );
}


export const query = graphql`
  query {
    image: file(relativePath: { eq: "programmer.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
